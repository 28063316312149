import { Input } from '@bdo/kitchensink'
import styles from './SearchField.module.scss'

type Props = {
  onSearch: (value: string) => void
  placeholder: string
  defaultValue: string
}

function SearchField(props: Readonly<Props>) {
  const { onSearch, placeholder, defaultValue } = props
  const { Search } = Input

  return (
    <div>
      <Search placeholder={placeholder} style={{ width: 400, borderRadius: 0 }} className={styles.searchField} allowClear onSearch={onSearch} defaultValue={defaultValue} />
    </div>
  )
}
export default SearchField
