'use client'

import { useState } from 'react'
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react'
import { IPublicClientApplication, SilentRequest } from '@azure/msal-browser'
import './styles/AntStyles.scss'
import './styles/AppPageStyles.scss'
import './styles/LayoutStyles.scss'
import './styles/GlobalStyles.scss'
import './styles/RefStyles.scss'
import Navigation from 'ui-components/Navigation/Navigation'
import Header from 'ui-components/Header/Header'
import { Routes, Route, Navigate } from 'react-router-dom'
import Clients from 'pages/Clients/Clients'
import LoginPage from 'pages/LoginPage/LoginPage'
import Questionnaires from 'pages/Questionnaires/Questionnaires'
import Questionnaire from 'pages/Questionnaire/Questionnaire'
import ClientPage from 'pages/ClientPage/ClientPage'
import ErrorPage from 'pages/ErrorPage/ErrorPage'
import GeneralLedger from 'pages/GeneralLedger/GeneralLedger'
import Account from 'pages/Account/Account'
import RoleProtectedRoute from 'utils/RoleProtectedRoute/RoleProtectedRoute'
import { I18nProvider, LOCALES } from 'i18n'
import TimeController from 'ui-components/TimeController/TimeController'
import LanguageSwitcher from 'ui-components/LanguageSwitcher/LanguageSwitcher'
import AccountDetails from 'features/generalLedger/components/AccountDetails/AccountDetails'
import Condition from 'features/generalLedger/components/Condition/Condition'
import Description from 'features/generalLedger/components/Description/Description'
import Templates from 'pages/Templates/Templates'
import QuestionnaireFill from 'pages/QuestionnaireFill/QuestionnaireFill'
import TabServicesApps from 'features/clientDetails/components/TabServicesApps/TabServicesApps'
import TabDocuments from 'features/clientDetails/components/TabDocuments/TabDocuments'
import TabClientDetails from 'features/clientDetails/components/TabClientDetails/TabClientDetails'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryPage from 'pages/BoundaryError/BoundaryErrorPage'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import SignalRContext from 'utils/SignalRContextHelper/SignalRContextHelper'
import { onboardingToolApiConfig } from 'app.config'
import SignalRReceiver, { SignalRSubscriptions } from 'ui-components/MessageReceivers/SignalRReceiver'
import { initializeIcons } from '@uifabric/icons'
import { reactPlugin } from '../../ApplicationInsightsService'

type AppProps = {
  pca: IPublicClientApplication
}

function App(props: Readonly<AppProps>) {
  initializeIcons('https://res.cdn.office.net/files/fabric-cdn-prod_20240129.001/assets/icons/')

  const { pca } = props
  const [locale, setLocale] = useState(LOCALES.ENGLISH)
  const pageLocation = window.location

  const getToken = async () => {
    const account = pca.getActiveAccount()
    if (!account) {
      return ''
    }
    const tokenRequest: SilentRequest = {
      scopes: onboardingToolApiConfig.scopes ?? [],
      account
    }
    const tokenResponse = await pca.acquireTokenSilent(tokenRequest)
    // setToken(tokenResponse.accessToken)
    // console.log('token', token)
    return tokenResponse.accessToken
  }

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={pca}>
        <I18nProvider locale={locale}>
          <AuthenticatedTemplate>
            <div className='appHeader'>
              <LanguageSwitcher onLangSwitch={(langCode: string) => setLocale(langCode)} />
              <Header />
            </div>
            <div className='flex'>
              <div className='appNav'>
                <Navigation />
              </div>

              <div className='appContent'>
                <SignalRContext.Provider
                  url={onboardingToolApiConfig.notificationHubUri ?? ''}
                  accessTokenFactory={getToken}
                  transport={4}
                  onBeforeClose={() =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve()
                      }, 1000)
                    })
                  }
                >
                  <SignalRReceiver subscription={SignalRSubscriptions.FilesExport} />
                  <TimeController />
                  <Routes>
                    <Route path='/' element={<Navigate to='/clients' />} />
                    <Route path='clients' element={<Clients />} />
                    <Route
                      path='clients/:id'
                      element={
                        <ErrorBoundary FallbackComponent={ErrorBoundaryPage} onError={LoggerHelper} key={pageLocation.pathname}>
                          <ClientPage />
                        </ErrorBoundary>
                      }
                    >
                      <Route path='services_apps' element={<TabServicesApps />} />
                      <Route path='documents' element={<TabDocuments />} />
                      <Route path='client_details' element={<TabClientDetails />} />
                    </Route>
                    <Route
                      path='clients/:id/questionnaires/:questionnaireId/application/:appId/instance/:instanceId/fill'
                      element={
                        <ErrorBoundary FallbackComponent={ErrorBoundaryPage} onError={LoggerHelper}>
                          <SignalRReceiver subscription={SignalRSubscriptions.QuestionnairePublished} />
                          <QuestionnaireFill />
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path='questionnaires'
                      element={
                        <RoleProtectedRoute userRole='Superuser'>
                          <Questionnaires />
                        </RoleProtectedRoute>
                      }
                    />
                    <Route
                      path='questionnaires/:id/edit'
                      element={
                        <RoleProtectedRoute userRole='Superuser'>
                          <ErrorBoundary FallbackComponent={ErrorBoundaryPage} onError={LoggerHelper}>
                            <Questionnaire />
                          </ErrorBoundary>
                        </RoleProtectedRoute>
                      }
                    />
                    <Route
                      path='generalLedger'
                      element={
                        <RoleProtectedRoute userRole='Superuser'>
                          <GeneralLedger />
                        </RoleProtectedRoute>
                      }
                    />
                    <Route
                      path='generalLedger/:id'
                      element={
                        <RoleProtectedRoute userRole='Superuser'>
                          <ErrorBoundary FallbackComponent={ErrorBoundaryPage} onError={LoggerHelper}>
                            <Account />
                          </ErrorBoundary>
                        </RoleProtectedRoute>
                      }
                    >
                      <Route path='account' element={<AccountDetails />} />
                      <Route path='condition' element={<Condition />} />
                      <Route path='description' element={<Description />} />
                    </Route>
                    <Route
                      path='templates'
                      element={
                        <RoleProtectedRoute userRole='Superuser'>
                          <Templates />
                        </RoleProtectedRoute>
                      }
                    />
                    <Route path='*' element={<ErrorPage status={404} />} />
                  </Routes>
                </SignalRContext.Provider>
              </div>
            </div>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <LoginPage />
          </UnauthenticatedTemplate>
        </I18nProvider>
      </MsalProvider>
    </AppInsightsContext.Provider>
  )
}

export default App
